import React from "react";
import map from "../../../img/vul-85-sediment.png";
let sedMap;
const Sediment85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    sedMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Potential to Sedimentation RCP 8.5</h1>
      </div>
      <div className="main-text">
        <p>
          Increased precipitation due to climate change increase soil erosion in
          the landscape. Therefore, 9 subbasins in the {activeWS.text}
          Watershed are classified as highly vulnerable to sedimentation, 25
          subbasins are categorized as moderately vulnerable, and 17 subbasins
          are considered as least vulnerable to sedimentation. Subbasins
          classified as highly and moderately vulnerable to sedimentation have
          more subbasin area as agricultural lands. Additionally, highly
          vulnerable subasins to sedimentation have higher slope compared to
          moderately and low vulnerable subbsins to sedimentation.
        </p>
      </div>
      <div className="main-img">
        <img src={sedMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Sediment85;
