import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Table from "../../components/Table/Table";
import { usRcp85, usrcp85Table } from "../../data/upper_salem/uncertainty";

let data;

const options = {
  chart: {
    type: "column",
  },
  title: { text: "" },
  yAxis: { title: { text: "streamflow (ft3/sec)" } },
  xAxis: {
    categories: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
};

const RCP85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usRcp85;
    data = usrcp85Table;
  }
  options.series[6].type = "spline";
  options.series[7].type = "spline";

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Uncertainty Analysis RCP 8.5</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          The RCP 8.5 is also known as extreme greenhouse emission scenario
          under climate change. The primary uncertainty in climate change study
          comes from the Global Climate Models (GCMs), therefore, streamflow
          generated from the calibrated hydrologic model (SWAT) using climate
          data (precipitation and temperature) for each GCM is compared with the
          observed monthly streamflow from 2003 to 2015. Additionally, a paired
          two sample t-test is conducted between observed and simulated
          streamflow using GCMs.
        </p>
        <br />
        <p>
          The visual comparison shows that the streamflow is highest during
          spring and lowest during summer while it shows an increasing trend
          during fall season. Compared to individual GCM, ensemble mean shows
          better agreement with observed streamflow. Therefore, an ensemble
          approach is used for this study.
        </p>
        <br />
        <p>
          <strong>Table:</strong> Table: Comparison of individual as well as
          ensemble mean against observed streamflow for uncertainty analysis
          from 2003 to 2015 under RCP-8.5 emission scenario.
        </p>
        <br />
        <Table
          data={data}
          foot={
            // <span>
            "** indicates significant at least 5% level of significance."
            // </span>
          }
        />

        <br />
        <p>
          The paired two sample t-test reveals that the streamflow from
          individual GCMs are statistically insignificant at 5% level of
          significance except IPSL-CM5A-LR. The P-values of all GCMs except
          IPSL-CM5A-LR are greater than 0.05. Therefore, IPSL-CM5A-LR is not
          included in the climate change analysis.
        </p>
      </div>
    </div>
  );
};

export default RCP85;
