import React from "react";
import map from "../../../img/vul-45-eutroph.png";

let eutMap;

const Eutroph45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    eutMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Potential to Eutrophication RCP 4.5</h1>
      </div>
      <div className="main-text">
        <p>
          Due to climate change, 8 subbasins are classified as highly vulnerable
          to eutrophication due to excess phosphorus load while 10 and 33
          subbasins are identified as moderate and low vulnerable to
          eutrophication, respectively. The increase in phosphorus load in the
          river is due to increased in sediment load combined with organic
          phosphorus.
        </p>
      </div>
      <div className="main-img">
        <img src={eutMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Eutroph45;
