import React from "react";
import "./Table.css";

// expect incoming data to be an array of objects

const Table = ({ data, foot }) => {
  const header = Object.keys(data[0]);

  let colWidth = 100 / header.length;
  return (
    <table className="table-container">
      <thead className="table-header">
        <TableHeader header={header} colWidth={colWidth} />
      </thead>
      <tbody className="table-body">
        <TableBody data={data} />
      </tbody>
      <tfoot className="table-footer">
        <TableFoot foot={foot} header={header} />
      </tfoot>
    </table>
  );
};

export default Table;

const TableHeader = ({ header, colWidth }) => {
  return (
    <tr className="table-row">
      {header.map((key, index) => {
        return (
          <th
            key={index}
            className="table-head"
            style={{ width: `${colWidth}%` }}
          >
            {key}
          </th>
        );
      })}
    </tr>
  );
};

const TableBody = ({ data }) => {
  return data.map((dat, index) => {
    let val = Object.values(dat);
    return (
      <tr key={index} className="table-row">
        {val.map((item) => {
          return <td className="table-data">{item}</td>;
        })}
      </tr>
    );
  });
};

const TableFoot = ({ foot, header }) => {
  return (
    <tr>
      <th className="tfooter-head" scope="row" colspan={header.length}>
        {/* <th className="tfooter-head" scope="row"> */}
        {foot}
      </th>
      {/* <td colspan="2" className="tfooter-data"></td> */}
    </tr>
  );
};
