export const usRcp45Base = {
  name: "Water(in)",
  data: [
    ["Surface Runoff", 6.05],
    ["Lateral Flow", 1.2],
    ["Groundwater", 13.77],
    ["ET", 22.6],
  ],
};
export const usRcp45Change = {
  name: "Water(in)",
  data: [
    ["Surface Runoff", 6.37],
    ["Lateral Flow", 1.3],
    ["Groundwater", 15.02],
    ["ET", 23.37],
  ],
};
export const usRcp85Base = {
  name: "Water(mm)",
  data: [
    ["Surface Runoff", 5.94],
    ["Lateral Flow", 1.23],
    ["Groundwater", 14.18],
    ["ET", 22.63],
  ],
};
export const usRcp85Change = {
  name: "Water(mm)",
  data: [
    ["Surface Runoff", 6.54],
    ["Lateral Flow", 1.34],
    ["Groundwater", 15.44],
    ["ET", 23.44],
  ],
};
