import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usHydro } from "../../data/upper_salem/climate_change_impact";

const options = {
  chart: {
    type: "column",
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
  },
  title: { text: "" },
  xAxis: {
    categories: ["Streamflow", "Surface Runoff"],
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  yAxis: {
    title: { text: "% Change from Baseline", style: { fontSize: "14px" } },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
};

const Hydrology = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usHydro;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - Hydrology</h1>
      </div>
      <div className="main-text">
        <p>
          The precipitation in the climate change scenarios increases which
          rises surface runoff, groundwater, and lateral flow. This results into
          an increase in average stream annual streamflow by 8% and 9% for RCP
          4.5 and RCP-8.5 scenarios, respectively. The surface runoff in the
          watershed increases by 5% and 10% under RCP 4.5 and RCP 8.5 scenarios,
          respectively.
        </p>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default Hydrology;
