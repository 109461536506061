export const usRcp45 = [
  { Period: "January", Base: 3.35, "Climate Change": 3.47, "% Change": 3.72 },
  { Period: "February", Base: 3.01, "Climate Change": 3.08, "% Change": 2.26 },
  { Period: "March", Base: 3.81, "Climate Change": 4.02, "% Change": 5.68 },
  { Period: "April", Base: 3.52, "Climate Change": 3.72, "% Change": 5.57 },
  { Period: "May", Base: 3.75, "Climate Change": 4.1, "% Change": 9.49 },
  { Period: "June", Base: 3.62, "Climate Change": 3.66, "% Change": 1.12 },
  { Period: "July", Base: 4.03, "Climate Change": 4.52, "% Change": 12.27 },
  { Period: "August", Base: 4.54, "Climate Change": 5.02, "% Change": 10.38 },
  {
    Period: "September",
    Base: 3.81,
    "Climate Change": 4.25,
    "% Change": 11.62,
  },
  { Period: "October", Base: 3.56, "Climate Change": 3.48, "% Change": -2.03 },
  { Period: "November", Base: 3.69, "Climate Change": 3.65, "% Change": -0.9 },
  { Period: "December", Base: 3.54, "Climate Change": 3.72, "% Change": 5.17 },
  { Period: "Annual", Base: 3.68, "Climate Change": 3.89, "% Change": 5.63 },
];
