import React from "react";
import Table from "../../components/Table/Table";
import { usRcp45 } from "../../data/upper_salem/temperature/rcp45";

let data;

const TempRCP45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    data = usRcp45;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Mean Monthly Temperature RCP 4.5</h1>
      </div>
      <div className="main-text">
        <p>
          The average monthly mean temperature between base and climate change
          shows a similar trend. The maximum temperature is observed during July
          while the minimum temperature is noticed during January. A gradual
          increasing trend of temperature is observed between January to July
          while a decreasing trend is found from July to December.
        </p>
        <br />
        <p>
          The visual comparison shows that the streamflow is highest during
          spring and lowest during summer while it shows an increasing trend
          during fall season. Compared to individual GCM, ensemble mean shows
          better agreement with observed streamflow. Therefore, an ensemble
          approach is used for this study.
        </p>
        <br />
        <p>
          <strong>Table: </strong>Monthly ensemble mean temperature (°F) for
          base and climate change as well as % increase in temperature due to
          climate change in the {activeWS.text} Watershed.
        </p>
        <br />
        <Table data={data} />
        <br />
        <p>
          In terms of percentage increase in temperature due to climate change,
          a maximum increasing temperature is noticed from January to March
          whilst a minimum increase in temperature is found during summer months
          (May, June, and July). Overall, approximately, 2% increase in annual
          temperature is observed due to climate change in the watershed.
        </p>
      </div>
    </div>
  );
};

export default TempRCP45;
