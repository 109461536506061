import React from "react";
import map from "../../../img/vul-45-sediment.png";
let sedMap;
const Sediment45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    sedMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Potential to Sedimentation RCP 4.5</h1>
      </div>
      <div className="main-text">
        <p>
          Increased precipitation due to climate change increase soil erosion in
          the landscape. Therefore, 15 subbasins in the {activeWS.text}
          Watershed are classified as highly vulnerable to sedimentation, 14
          subbasins are categorized as moderately vulnerable, and 22 subbasins
          are considered as least vulnerable to sedimentation. Subbasins
          classified as highly and moderately vulnerable to sedimentation have
          approximately 73% subbasin area as agricultural lands. Additionally,
          highly vulnerable subasins to sedimentation have higher slope compared
          to moderately and low vulnerable subbsins to sedimentation.
        </p>
      </div>
      <div className="main-img">
        <img src={sedMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Sediment45;
