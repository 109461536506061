import * as RiIcons from "react-icons/ri";
export const sidebarData = [
  {
    title: "Model Performance",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "model",
    subNav: [
      {
        title: "Streamflow",
        path: "/model_performance/streamflow",
      },
      {
        title: "Sediment",
        path: "/model_performance/sediment",
      },
      {
        title: "TP",
        path: "/model_performance/tp",
      },
    ],
  },
  {
    title: "Uncertainty Analysis (Streamflow)",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "uncer",
    subNav: [
      {
        title: "RCP-4.5",
        path: "/uncertainty/rcp45",
      },
      {
        title: "RCP-8.5",
        path: "/uncertainty/rcp85",
      },
    ],
  },
  {
    title: "Mean Monthly Temperature",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "temp",
    subNav: [
      {
        title: "RCP-4.5",
        path: "/temperature/rcp45",
        name: "tempRcp4",
      },
      {
        title: "RCP-8.5",
        path: "/temperature/rcp85",
        name: "tempRcp8",
      },
    ],
  },
  {
    title: "Mean Monthly Precipitation",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "pptn",
    subNav: [
      {
        title: "RCP-4.5",
        path: "/precip/rcp45",
        name: "pptnRcp4",
      },
      {
        title: "RCP-8.5",
        path: "/precip/rcp85",
        name: "pptnRcp8",
      },
    ],
  },
  {
    title: "Water Budget",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "water",
    subNav: [
      {
        title: "RCP-4.5",
        path: "/water/rcp45",
        name: "waterRcp4",
      },
      {
        title: "RCP-8.5",
        path: "/water/rcp85",
        name: "waterRcp8",
      },
    ],
  },
  {
    title: "Climate Change Impact",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "clima",
    subNav: [
      {
        title: "Hydrology",
        path: "/impact/hydrology",
        name: "hydro",
      },
      {
        title: "Water Quality",
        path: "/impact/quality",
        name: "qual",
      },
    ],
  },
  {
    title: "Detail Analysis of TP",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "detai",
    subNav: [
      {
        title: "RCP-4.5",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "detailRcp4",
        subNav: [
          {
            title: "Organic P",
            path: "/tp/rcp45/organic",
            name: "organ4",
          },
          {
            title: "Mineral P",
            path: "/tp/rcp45/mineral",
            name: "miner4",
          },
        ],
      },
      {
        title: "RCP-8.5",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "detailRcp8",
        subNav: [
          {
            title: "Organic P",
            path: "/tp/rcp85/organic",
            name: "organ8",
          },
          {
            title: "Mineral P",
            path: "/tp/rcp85/mineral",
            name: "miner8",
          },
        ],
      },
    ],
  },
  {
    title: "Climate Change Vulnerability Index",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "index",
    subNav: [
      {
        title: "RCP-4.5",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "indexRcp4",
        subNav: [
          {
            title: "Potential to Flooding",
            path: "/vulnerable/rcp45/flooding",
          },
          {
            title: "Potential to Sedimentation",
            path: "/vulnerable/rcp45/sediment",
          },
          {
            title: "Potential to Eutrophication",
            path: "/vulnerable/rcp45/eutroph",
          },
          {
            title: "Composite Index",
            path: "/vulnerable/rcp45/composite",
          },
        ],
      },
      {
        title: "RCP-8.5",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "indexRcp8",
        subNav: [
          {
            title: "Potential to Flooding",
            path: "/vulnerable/rcp85/flooding",
          },
          {
            title: "Potential to Sedimentation",
            path: "/vulnerable/rcp85/sediment",
          },
          {
            title: "Potential to Eutrophication",
            path: "/vulnerable/rcp85/eutroph",
          },
          {
            title: "Composite Index",
            path: "/vulnerable/rcp85/composite",
          },
        ],
      },
    ],
  },
  {
    title: "Adaptation Strategies",
    path: "#",
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDownSFill />,
    name: "adapt",
    subNav: [
      {
        title: "BMP Application Area (10%)",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "bmp10",
        subNav: [
          {
            title: "RCP-4.5",
            path: "#",
            iconClosed: <RiIcons.RiArrowRightSFill />,
            iconOpened: <RiIcons.RiArrowDownSFill />,
            name: "bmp104",
            subNav: [
              {
                title: "Streamflow",
                path: "/adaptation/bmp10/rcp45/streamflow",
                name: "strea104",
              },
              {
                title: "Surface Runoff",
                path: "/adaptation/bmp10/rcp45/runoff",
                name: "surfa104",
              },
              {
                title: "Sediment",
                path: "/adaptation/bmp10/rcp45/sediment",
                name: "sedim104",
              },
              {
                title: "TP",
                path: "/adaptation/bmp10/rcp45/tp",
                name: "tp104",
              },
            ],
          },
          {
            title: "RCP-8.5",
            path: "#",
            iconClosed: <RiIcons.RiArrowRightSFill />,
            iconOpened: <RiIcons.RiArrowDownSFill />,
            name: "bmp108",
            subNav: [
              {
                title: "Streamflow",
                path: "/adaptation/bmp10/rcp85/streamflow",
                name: "strea108",
              },
              {
                title: "Surface Runoff",
                path: "/adaptation/bmp10/rcp85/runoff",
                name: "surfa108",
              },
              {
                title: "Sediment",
                path: "/adaptation/bmp10/rcp85/sediment",
                name: "sedim108",
              },
              {
                title: "TP",
                path: "/adaptation/bmp10/rcp85/tp",
                name: "tp108",
              },
            ],
          },
        ],
      },
      {
        title: "BMP Application Area (46%)",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        name: "bmp46",
        subNav: [
          {
            title: "RCP-4.5",
            path: "#",
            iconClosed: <RiIcons.RiArrowRightSFill />,
            iconOpened: <RiIcons.RiArrowDownSFill />,
            name: "bmp464",
            subNav: [
              {
                title: "Streamflow",
                path: "/adaptation/bmp46/rcp45/streamflow",
                name: "strea464",
              },
              {
                title: "Surface Runoff",
                path: "/adaptation/bmp46/rcp45/runoff",
                name: "surfa464",
              },
              {
                title: "Sediment",
                path: "/adaptation/bmp46/rcp45/sediment",
                name: "sedim464",
              },
              {
                title: "TP",
                path: "/adaptation/bmp46/rcp45/tp",
                name: "tp464",
              },
            ],
          },
          {
            title: "RCP-8.5",
            path: "#",
            iconClosed: <RiIcons.RiArrowRightSFill />,
            iconOpened: <RiIcons.RiArrowDownSFill />,
            name: "bmp468",
            subNav: [
              {
                title: "Streamflow",
                path: "/adaptation/bmp46/rcp85/streamflow",
                name: "strea468",
              },
              {
                title: "Surface Runoff",
                path: "/adaptation/bmp46/rcp85/runoff",
                name: "surfa468",
              },
              {
                title: "Sediment",
                path: "/adaptation/bmp46/rcp85/sediment",
                name: "sedim468",
              },
              {
                title: "TP",
                path: "/adaptation/bmp46/rcp85/tp",
                name: "tp468",
              },
            ],
          },
        ],
      },
    ],
  },
];
