import React from "react";
import Submenu from "./Submenu";
import "./Sidebar.css";

const Sidebar = ({ data, activeWS }) => {
  let currentWS = {
    title: activeWS.text,
    path: "/overview",
  };
  return (
    <nav className="sidebar-nav">
      <div className="sidebar-wrap">
        <Submenu item={currentWS} />
        {data.map((item, index) => {
          return <Submenu item={item} key={index} padding={0} />;
        })}
      </div>
    </nav>
  );
};

export default Sidebar;
