import React from "react";
import Table from "../../components/Table/Table";
import { usRcp45 } from "../../data/upper_salem/precipitation/rcp45";

let data;

const PcpnRCP45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    data = usRcp45;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Mean Monthly Precipitation RCP 4.5</h1>
      </div>
      <div className="main-text">
        <p>
          The monthly ensemble mean precipitation between base and climate
          change scenario displays a similar trend where maximum precipitation
          is observed during August and minimum precipitation is found during
          February. Based on percent change in precipitation between base and
          climate change scenario, an increase in precipitation is observed for
          all months except October and November. The highest percent increase
          in precipitation is noticed during July while a maximum reduction in
          precipitation is found during October. Overall, an increase in
          precipitation is observed on average annual basis.
        </p>
        <br />

        <p>
          <strong>Table: </strong>Monthly ensemble mean precipitation (in) for
          base and climate change as well as % change in precipitation due to
          climate change in the {activeWS.text} Watershed.
        </p>
        <br />
        <Table data={data} />
      </div>
    </div>
  );
};

export default PcpnRCP45;
