import usr_map from "../img/upper_salem_river.jpeg";

export const watersheds = [
  {
    text: "Upper Salem River",
    wma: "Maurice, Salem, and Cohansey",
    region: "Lower Delaware",
    id: "17BA01",
    img_source: { usr_map },
    img_size: 416,
    data: "upper_salem",
  },
];
