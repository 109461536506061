import React from "react";
import map from "../../../img/vul-85-composite.png";
let compMap;
const Composite85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    compMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Composite Index RCP 8.5</h1>
      </div>
      <div className="main-text">
        <p>
          Based on composite vulnerability index, 13 subbasins, 23 subbasins,
          and 15 subbasins are identified as highly vulnerable, moderately
          vulnerable, and least vulnerable due to climate change, respectively.
          Here, flooding, sedimentation, and eutrophication have assigned equal
          weight while calculating composite vulnerability index.
        </p>
      </div>
      <div className="main-img">
        <img src={compMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Composite85;
