import React from "react";
import map from "../../../img/vul-45-flooding.png";

let floodMap;
const Flooding45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    floodMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Potential to Flooding RCP 4.5</h1>
      </div>
      <div className="main-text">
        <p>
          The vulnerability to flooding in the watershed is categorized into
          three classes: i{")"} high, ii{")"} medium, and iii{")"} low. Out of
          51 sub-basins, 10 sub-basins are classified as highly vulnerable to
          flooding due to higher increased in surface runoff while 17 and 24
          sub-basins are categorized as moderate and low vulnerable to flooding.
          Sub-basins identified as highly vulnerable to flooding have average
          sub-basin area of 21% as urban land while it is 10% and 3% for
          moderate and low categories, respectively.
        </p>
      </div>
      <div className="main-img">
        <img src={floodMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Flooding45;
