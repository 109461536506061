export const usBmp10Rcp45Runoff = {
  chart: {
    name: "Surface runoff (inch/yr)",
    data: [
      {
        name: "Base",
        low: 5.6,
        q1: 5.85,
        median: 5.86,
        q3: 6.35,
        high: 6.59,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 5.95,
        q1: 6.2,
        median: 6.43,
        q3: 6.62,
        high: 6.66,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 5.93,
        q1: 6.18,
        median: 6.41,
        q3: 6.59,
        high: 6.64,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 5.95,
        q1: 6.19,
        median: 6.43,
        q3: 6.61,
        high: 6.66,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 5.37,
        q1: 5.59,
        median: 5.71,
        q3: 6.01,
        high: 6.01,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 5,
    value: 11,
  },
};

export const usBmp10Rcp45Sediment = {
  chart: {
    name: "Sediment (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2660976.34,
        q1: 2775616.58,
        median: 2848369.04,
        q3: 3004897.06,
        high: 3267246.84,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2896870.68,
        q1: 3095286.48,
        median: 3097491.1,
        q3: 3128355.78,
        high: 3500936.56,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2817504.36,
        q1: 3020329.4,
        median: 3024738.64,
        q3: 3048989.46,
        high: 3428184.1,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2810890.5,
        q1: 3007101.68,
        median: 3029147.88,
        q3: 3082058.76,
        high: 3432593.34,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2738138.04,
        q1: 2905689.16,
        median: 2967418.52,
        q3: 2971827.76,
        high: 3317953.1,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 7,
    value: 3.3,
  },
};

export const usBmp10Rcp45Streamflow = {
  chart: {
    name: "Streamflow (cubic feet/sec)",
    data: [
      {
        name: "Base",
        low: 20.43,
        q1: 21.14,
        median: 21.22,
        q3: 21.22,
        high: 23.01,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 21.77,
        q1: 22.16,
        median: 22.96,
        q3: 23.43,
        high: 25.26,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 21.43,
        q1: 21.8,
        median: 22.62,
        q3: 23.05,
        high: 24.92,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 21.68,
        q1: 22.08,
        median: 22.87,
        q3: 23.35,
        high: 25.17,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 21.78,
        q1: 22.02,
        median: 22.97,
        q3: 23.45,
        high: 25.27,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 10,
    value: 10,
  },
};

export const usBmp10Rcp45TP = {
  chart: {
    name: "TP (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2619.08856,
        q1: 2733.7288,
        median: 2879.23372,
        q3: 3024.73864,
        high: 3119.5373,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2656.5671,
        q1: 2769.00272,
        median: 2850.57366,
        q3: 2899.0753,
        high: 2938.75846,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2577.20078,
        q1: 2696.25026,
        median: 2771.20734,
        q3: 2815.29974,
        high: 2852.77828,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2431.69586,
        q1: 2519.88066,
        median: 2599.24698,
        q3: 2630.11166,
        high: 2671.99944,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2656.5671,
        q1: 2760.18424,
        median: 2850.57366,
        q3: 2892.46144,
        high: 2925.53074,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 0.2,
    value1: 3,
    value2: 9,
  },
};

export const usBmp10Rcp85Runoff = {
  chart: {
    name: "Surface runoff (inch/yr)",
    data: [
      {
        name: "Base",
        low: 5.71,
        q1: 5.74,
        median: 5.94,
        q3: 6.07,
        high: 6.25,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 6.06,
        q1: 6.45,
        median: 6.64,
        q3: 6.64,
        high: 6.92,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 6.04,
        q1: 6.42,
        median: 6.61,
        q3: 6.63,
        high: 6.91,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 6.05,
        q1: 6.45,
        median: 6.63,
        q3: 6.65,
        high: 6.91,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 5.48,
        q1: 5.85,
        median: 5.98,
        q3: 6.02,
        high: 6.28,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 12,
    value: 11,
  },
};

export const usBmp10Rcp85Sediment = {
  chart: {
    name: "Sediment (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2700659.5,
        q1: 2879233.72,
        median: 2967418.52,
        q3: 3022534.02,
        high: 3046784.84,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 3018124.78,
        q1: 3194494.38,
        median: 3386296.32,
        q3: 3476685.74,
        high: 3639827.62,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2940963.08,
        q1: 3097491.1,
        median: 3298111.52,
        q3: 3417161,
        high: 3542824.34,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2947576.94,
        q1: 3115128.06,
        median: 3317953.1,
        q3: 3432593.34,
        high: 3540619.72,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2839550.56,
        q1: 3018124.78,
        median: 3238586.78,
        q3: 3280474.56,
        high: 3492118.08,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 14,
    value: 6,
  },
};

export const usBmp10Rcp85Streamflow = {
  chart: {
    name: "Streamflow (cubic feet/sec)",
    data: [
      {
        name: "Base",
        low: 21.32,
        q1: 21.39,
        median: 21.6,
        q3: 21.91,
        high: 22.53,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 23.19,
        q1: 22.03,
        median: 23.17,
        q3: 24.53,
        high: 25.89,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 22.81,
        q1: 21.69,
        median: 22.78,
        q3: 24.24,
        high: 25.55,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 23.11,
        q1: 21.94,
        median: 23.1,
        q3: 24.46,
        high: 25.82,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 23.2,
        q1: 22.04,
        median: 23.17,
        q3: 24.55,
        high: 25.9,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 8,
    value: 13,
  },
};

export const usBmp10Rcp85TP = {
  chart: {
    name: "TP (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2647.74862,
        q1: 2762.38886,
        median: 2804.27664,
        q3: 2843.9598,
        high: 2947.57694,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2857.18752,
        q1: 2912.30302,
        median: 2954.1908,
        q3: 2954.1908,
        high: 3121.74192,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2742.54728,
        q1: 2793.25354,
        median: 2819.70898,
        q3: 2824.11822,
        high: 3009.3063,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2519.88066,
        q1: 2548.54072,
        median: 2590.4285,
        q3: 2592.63312,
        high: 2735.93342,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2843.9598,
        q1: 2892.46144,
        median: 2927.73536,
        q3: 2938.75846,
        high: 3104.10496,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 5,
    value1: 0.6,
    value2: 3.3,
    value3: 12,
  },
};

export const usBmp46Rcp45Runoff = {
  chart: {
    name: "Surface runoff (inch/yr)",
    data: [
      {
        name: "Base",
        low: 5.6,
        q1: 5.85,
        median: 5.86,
        q3: 6.35,
        high: 6.59,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 5.95,
        q1: 6.2,
        median: 6.43,
        q3: 6.62,
        high: 6.66,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 5.93,
        q1: 6.18,
        median: 6.41,
        q3: 6.59,
        high: 6.64,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 5.95,
        q1: 6.19,
        median: 6.43,
        q3: 6.61,
        high: 6.66,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 5.37,
        q1: 5.59,
        median: 5.71,
        q3: 6.01,
        high: 6.01,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 5,
    value: 11,
  },
};

export const usBmp46Rcp45Sediment = {
  chart: {
    name: "Sediment (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2660976.34,
        q1: 2775616.58,
        median: 2848369.04,
        q3: 3004897.06,
        high: 3267246.84,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2896870.68,
        q1: 3095286.48,
        median: 3097491.1,
        q3: 3128355.78,
        high: 3500936,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2817504.36,
        q1: 3020329.4,
        median: 3024738.64,
        q3: 3048989.46,
        high: 3428184.1,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2810890.5,
        q1: 3007101.68,
        median: 3029147.88,
        q3: 3082058.76,
        high: 3432593.34,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2738138.04,
        q1: 2905689.16,
        median: 2967418.52,
        q3: 2971827.76,
        high: 3317953.1,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 7,
    value: 3.2,
  },
};

export const usBmp46Rcp45Streamflow = {
  chart: {
    name: "Streamflow (cubic feet/sec)",
    data: [
      {
        name: "Base",
        low: 20.43,
        q1: 21.14,
        median: 21.22,
        q3: 21.22,
        high: 23.01,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 21.77,
        q1: 22.16,
        median: 22.96,
        q3: 23.43,
        high: 25.26,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 21.43,
        q1: 21.8,
        median: 22.62,
        q3: 23.05,
        high: 24.92,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 21.68,
        q1: 22.08,
        median: 22.87,
        q3: 23.35,
        high: 25.17,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 21.78,
        q1: 22.02,
        median: 22.97,
        q3: 23.45,
        high: 25.27,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 7,
    value: 46,
  },
};

export const usBmp46Rcp45TP = {
  chart: {
    name: "TP (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2619.09,
        q1: 2733.73,
        median: 2879.23,
        q3: 3024.74,
        high: 3119.54,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2656.57,
        q1: 2769,
        median: 2850.57,
        q3: 2899.08,
        high: 2938.76,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2303.83,
        q1: 2449.33,
        median: 2495.63,
        q3: 2530.9,
        high: 2555.15,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 1526.48,
        q1: 1533.09,
        median: 1576.96,
        q3: 1589.31,
        high: 1611.58,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2685.23,
        q1: 2766.8,
        median: 2793.25,
        q3: 2905.69,
        high: 2910.1,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 1,
    value1: 11,
    value2: 44,
  },
};

export const usBmp46Rcp85Runoff = {
  chart: {
    name: "Surface runoff (inch/yr)",
    data: [
      {
        name: "Base",
        low: 5.71,
        q1: 5.74,
        median: 5.94,
        q3: 6.07,
        high: 6.25,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 6.06,
        q1: 6.45,
        median: 6.64,
        q3: 6.64,
        high: 6.92,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 6.04,
        q1: 6.42,
        median: 6.61,
        q3: 6.63,
        high: 6.91,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 6.05,
        q1: 6.45,
        median: 6.63,
        q3: 6.65,
        high: 6.91,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 5.48,
        q1: 5.85,
        median: 5.98,
        q3: 6.02,
        high: 6.28,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 12,
    value: 11,
  },
};

export const usBmp46Rcp85Sediment = {
  chart: {
    name: "Sediment (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2700659.5,
        q1: 2879233.72,
        median: 2967418.52,
        q3: 3022534.02,
        high: 3046784.84,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 3018124.78,
        q1: 3194494.38,
        median: 3386296.32,
        q3: 3476685.74,
        high: 3639827.62,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2940963.08,
        q1: 3097491.1,
        median: 3298111.52,
        q3: 3417161,
        high: 3542824.34,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 2947576.94,
        q1: 3115128.06,
        median: 3317953.1,
        q3: 3432593.34,
        high: 3540619.72,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2839550.56,
        q1: 3018124.78,
        median: 3238586.78,
        q3: 3280474.56,
        high: 3492118.08,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 15,
    value: 5.5,
  },
};

export const usBmp46Rcp85Streamflow = {
  chart: {
    name: "Streamflow (cubic feet/sec)",
    data: [
      {
        name: "Base",
        low: 21.31948439,
        q1: 21.38658232,
        median: 21.60200199,
        q3: 21.90923988,
        high: 22.53431007,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 22.03284133,
        q1: 23.1664432,
        median: 23.19469496,
        q3: 24.53312209,
        high: 25.89273804,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 21.68675727,
        q1: 22.78151297,
        median: 22.8132962,
        q3: 24.24001008,
        high: 25.55371692,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 21.94102311,
        q1: 23.10287674,
        median: 23.11347115,
        q3: 24.45896122,
        high: 25.8150457,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 22.0363728,
        q1: 23.16997467,
        median: 23.19822643,
        q3: 24.54724797,
        high: 25.89980098,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 9,
    value: 46,
  },
};

export const usBmp46Rcp85TP = {
  chart: {
    name: "TP (lb/yr)",
    data: [
      {
        name: "Base",
        low: 2647.75,
        q1: 2762.39,
        median: 2804.28,
        q3: 2843.96,
        high: 2947.58,
        color: "#E3BA22",
        fillColor: "#E3BA22",
      },

      {
        name: "Climate Change",
        low: 2857.19,
        q1: 2912.3,
        median: 2954.19,
        q3: 2954.19,
        high: 3121.74,
        color: "#E6842A",
        fillColor: "#E6842A",
      },

      {
        name: "Cover Crop",
        low: 2471.38,
        q1: 2495.63,
        median: 2513.27,
        q3: 2524.29,
        high: 2740.34,
        color: "#137B80",
        fillColor: "#137B80",
      },

      {
        name: "Filter Strip",
        low: 1603.64,
        q1: 1623.48,
        median: 1627.23,
        q3: 1651.04,
        high: 1712.11,
        color: "#8E6C8A",
        fillColor: "#8E6C8A",
      },

      {
        name: "No Till",
        low: 2848.37,
        q1: 2890.26,
        median: 2896.87,
        q3: 2938.76,
        high: 3112.92,
        color: "#978F80",
        fillColor: "#978F80",
      },
    ],
  },
  page: {
    increase: 7,
    value1: 1,
    value2: 15,
    value3: 46,
  },
};
