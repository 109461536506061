export const usRcp45 = [
  {
    name: "CCSM4",
    data: [
      [32.9975],
      [33.2347],
      [30.1593],
      [28.5495],
      [22.8799],
      [19.0072],
      [17.9719],
      [20.9036],
      [21.3678],
      [17.1915],
      [20.9875],
      [26.5338],
    ],
    color: "#E3BA22",
  },
  {
    name: "CSIRO-MK3-6-0",
    data: [
      [31.4917],
      [30.7789],
      [29.0409],
      [25.4448],
      [22.9412],
      [19.2079],
      [14.111],
      [15.5439],
      [21.0395],
      [16.4683],
      [18.5782],
      [26.3087],
    ],
    color: "#E6842A",
  },
  {
    name: "GFDL-ESM2G",
    data: [
      [29.3924],
      [28.3729],
      [25.6569],
      [24.9126],
      [22.2326],
      [20.3878],
      [15.7311],
      [17.1012],
      [9.3365],
      [13.5357],
      [16.2393],
      [22.3596],
    ],
    color: "#137B80",
  },
  {
    name: "IPSL-CM5A-LR",
    data: [
      [26.8096],
      [28.6302],
      [27.4235],
      [28.5519],
      [25.8121],
      [17.6096],
      [15.4791],
      [12.068],
      [12.5595],
      [17.2174],
      [18.0665],
      [21.0079],
    ],
    color: "#8E6C8A",
  },
  {
    name: "MIROC5",
    data: [
      [26.8416],
      [30.0447],
      [25.4326],
      [23.0917],
      [19.3785],
      [19.1259],
      [11.6618],
      [14.2927],
      [15.6202],
      [15.5323],
      [21.6447],
      [22.4653],
    ],
    color: "#978F80",
  },
  {
    name: "MPI-ESM-LR",
    data: [
      [27.812],
      [31.0324],
      [31.6287],
      [29.7298],
      [26.8848],
      [25.074],
      [16.2197],
      [13.5544],
      [9.0971],
      [12.118],
      [15.7602],
      [22.6237],
    ],
    color: "#5C8100",
  },
  {
    name: "RCP-4.5 Ensemble Mean",
    data: [
      [28.9474],
      [29.9254],
      [27.9044],
      [26.9497],
      [23.9197],
      [20.7777],
      [15.7192],
      [15.3118],
      [15.0478],
      [15.6168],
      [18.3641],
      [23.349],
    ],
    color: "#c03",
  },
  {
    name: "Observed",
    data: [
      [24.5208],
      [29.9308],
      [33.2008],
      [30.547],
      [17.0801],
      [20.7822],
      [14.7336],
      [22.3487],
      [11.9495],
      [18.0358],
      [18.8406],
      [31.2974],
    ],
    color: "#007FAC",
  },
];

export const usRcp85 = [
  {
    name: "CCSM4",
    data: [
      [25.8626],
      [32.6444],
      [28.8486],
      [28.8073],
      [22.8712],
      [20.0324],
      [15.7683],
      [18.3947],
      [19.6887],
      [19.8561],
      [23.083],
      [25.2046],
    ],
    color: "#E3BA22",
  },
  {
    name: "CSIRO-MK3-6-0",
    data: [
      [35.8232],
      [31.9851],
      [28.7005],
      [28.2124],
      [23.3938],
      [16.3797],
      [16.75],
      [14.2732],
      [15.8703],
      [16.2365],
      [23.9146],
      [28.1282],
    ],
    color: "#E6842A",
  },
  {
    name: "GFDL-ESM2G",
    data: [
      [29.0719],
      [31.0864],
      [29.9917],
      [24.0558],
      [22.2912],
      [17.2116],
      [14.4543],
      [14.4143],
      [11.6984],
      [19.3076],
      [19.8379],
      [27.6924],
    ],
    color: "#137B80",
  },
  {
    name: "IPSL-CM5A-LR",
    data: [
      [24.9675],
      [26.8381],
      [25.2476],
      [29.2908],
      [23.1974],
      [15.9613],
      [12.2387],
      [8.993],
      [13.1297],
      [14.5187],
      [11.843],
      [16.6183],
    ],
    color: "#8E6C8A",
  },
  {
    name: "MIROC5",
    data: [
      [28.1043],
      [28.0708],
      [28.4305],
      [27.9717],
      [21.4377],
      [17.1189],
      [14.0303],
      [15.3752],
      [16.3397],
      [15.5469],
      [24.6122],
      [28.2431],
    ],
    color: "#978F80",
  },
  {
    name: "MPI-ESM-LR",
    data: [
      [29.2395],
      [33.0728],
      [33.0779],
      [30.5942],
      [24.7483],
      [20.2856],
      [12.4043],
      [17.3987],
      [13.2971],
      [14.2952],
      [16.0182],
      [22.97],
    ],
    color: "#5C8100",
  },
  {
    name: "RCP-8.5 Ensemble Mean",
    data: [
      [28.689],
      [30.1578],
      [29.1269],
      [27.7794],
      [23.3348],
      [18.8111],
      [14.491],
      [13.9859],
      [13.9496],
      [16.6546],
      [19.6023],
      [24.2213],
    ],
    color: "#c03",
  },
  {
    name: "Observed",
    data: [
      [24.5208],
      [29.9308],
      [33.2008],
      [30.547],
      [17.0801],
      [20.7822],
      [14.7336],
      [22.3487],
      [11.9495],
      [18.0358],
      [18.8406],
      [31.2974],
    ],
    color: "#007FAC",
  },
];

export const usRcp45Table = [
  {
    GCM: "CCSM4",
    "Mean 1": 22.6,
    "Mean 2": 24.37,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": -1.16,
    "P-value": 0.27,
  },
  {
    GCM: "CSIRO-MK3-6-0",
    "Mean 1": 22.6,
    "Mean 2": 22.6,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 0.13,
    "P-value": 0.9,
  },
  {
    GCM: "GFDL-ESM2G",
    "Mean 1": 22.6,
    "Mean 2": 20.48,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 1.81,
    "P-value": 0.1,
  },
  {
    GCM: "IPSL-CM5A-LR",
    "Mean 1": 22.6,
    "Mean 2": 20.84,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 1.21,
    "P-value": 0.25,
  },
  {
    GCM: "MIROC5",
    "Mean 1": 22.6,
    "Mean 2": 20.48,
    "Variance 1": 0.04,
    "Variance 2": 0.02,
    Df: 11,
    "t-Stat": 1.73,
    "P-value": 0.11,
  },
  {
    GCM: "MPI-ESM-LR",
    "Mean 1": 22.6,
    "Mean 2": 21.9,
    "Variance 1": 0.04,
    "Variance 2": 0.05,
    Df: 11,
    "t-Stat": 0.62,
    "P-value": 0.55,
  },
  {
    GCM: "Ensemble Mean",
    "Mean 1": 22.6,
    "Mean 2": 21.9,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 0.73,
    "P-value": 0.48,
  },
];

export const usrcp85Table = [
  {
    GCM: "CCSM4",
    "Mean 1": 22.6,
    "Mean 2": 23.31,
    "Variance 1": 0.04,
    "Variance 2": 0.02,
    Df: 11,
    "t-Stat": -0.53,
    "P-value": 0.6,
  },
  {
    GCM: "CSIRO-MK3-6-0",
    "Mean 1": 22.6,
    "Mean 2": 23.31,
    "Variance 1": 0.04,
    "Variance 2": 0.04,
    Df: 11,
    "t-Stat": -0.33,
    "P-value": 0.74,
  },
  {
    GCM: "GFDL-ESM2G",
    "Mean 1": 22.6,
    "Mean 2": 21.9,
    "Variance 1": 0.04,
    "Variance 2": 0.04,
    Df: 11,
    "t-Stat": 0.87,
    "P-value": 0.41,
  },
  {
    GCM: "IPSL-CM5A-LR",
    "Mean 1": 22.6,
    "Mean 2": 18.72,
    "Variance 1": 0.04,
    "Variance 2": 0.04,
    Df: 11,
    "t-Stat": 2.46,
    "P-value": "0.03**",
  },
  {
    GCM: "MIROC5",
    "Mean 1": 22.6,
    "Mean 2": 22.25,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 0.55,
    "P-value": 0.59,
  },
  {
    GCM: "MPI-ESM-LR",
    "Mean 1": 22.6,
    "Mean 2": 22.25,
    "Variance 1": 0.04,
    "Variance 2": 0.05,
    Df: 11,
    "t-Stat": 0.39,
    "P-value": 0.71,
  },
  {
    GCM: "Ensemble Mean",
    "Mean 1": 22.6,
    "Mean 2": 21.9,
    "Variance 1": 0.04,
    "Variance 2": 0.03,
    Df: 11,
    "t-Stat": 0.85,
    "P-value": 0.41,
  },
];
