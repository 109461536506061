import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Table from "../../components/Table/Table";
import { usRcp45, usRcp45Table } from "../../data/upper_salem/uncertainty";
let data;
const options = {
  chart: {
    type: "column",
  },
  title: { text: "" },
  yAxis: { title: { text: "streamflow (ft3/sec)" } },
  xAxis: {
    categories: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
};

const RCP45 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usRcp45;
    data = usRcp45Table;
  }

  options.series[6].type = "spline";
  options.series[7].type = "spline";

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Uncertainty Analysis RCP 4.5</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          The RCP 4.5 is also known as moderate greenhouse emission scenario
          under climate change. The primary uncertainty in climate change study
          comes from the Global Climate Models (GCMs), therefore, streamflow
          generated from the calibrated hydrologic model (SWAT) using climate
          data (precipitation and temperature) for each GCM is compared with the
          observed monthly streamflow from 2003 to 2015. Additionally, a paired
          two sample t-test is conducted between observed and simulated
          streamflow using GCMs.
        </p>
        <br />
        <p>
          The visual comparison shows that the streamflow is highest during
          spring and lowest during summer while it shows an increasing trend
          during fall season. Compared to individual GCM, ensemble mean shows
          better agreement with observed streamflow. Therefore, an ensemble
          approach is used for this study.
        </p>
        <br />
        <p>
          <strong>Table:</strong> Comparison of individual as well as ensemble
          mean against observed streamflow for uncertainty analysis from 2003 to
          2015 under RCP-4.5 emission scenario.
        </p>
        <br />
        <Table data={data} />
        <br />
        <p>
          The paired two sample t-test reveals that the streamflow from
          individual GCMs are statistically insignificant at 5% level of
          significance as P-values are greater than 0.05.
        </p>
      </div>
    </div>
  );
};

export default RCP45;
