export const usRcp85 = [
  { Period: "January", Base: 3.24, "Climate Change": 3.54, "% Change": 9.33 },
  { Period: "February", Base: 3.17, "Climate Change": 3.36, "% Change": 6.11 },
  { Period: "March", Base: 3.85, "Climate Change": 4.38, "% Change": 13.87 },
  { Period: "April", Base: 3.59, "Climate Change": 4.11, "% Change": 14.72 },
  { Period: "May", Base: 3.62, "Climate Change": 4.0, "% Change": 10.7 },
  { Period: "June", Base: 3.46, "Climate Change": 3.65, "% Change": 5.25 },
  { Period: "July", Base: 4.14, "Climate Change": 4.44, "% Change": 7.21 },
  { Period: "August", Base: 4.59, "Climate Change": 4.64, "% Change": 1.21 },
  { Period: "September", Base: 3.8, "Climate Change": 3.86, "% Change": 1.6 },
  { Period: "October", Base: 3.77, "Climate Change": 3.81, "% Change": 0.95 },
  { Period: "November", Base: 3.76, "Climate Change": 3.83, "% Change": 2.0 },
  { Period: "December", Base: 3.59, "Climate Change": 3.83, "% Change": 6.78 },
  { Period: "Annual", Base: 3.71, "Climate Change": 3.96, "% Change": 6.49 },
];
