import React from "react";
import Image from "./Image";
import Title from "./Title";
import Description from "./Description";
import "./SimpleCard.css";

const SimpleCard = ({ content, onClick }) => {
  return (
    <div className="card-simple">
      <Image
        source={Object.values(content.img_source)}
        size={content.img_size}
      />
      <div className="card-content">
        <Title text={content.text} url="/overview" onClick={onClick} />
        <Description
          wma={content.wma}
          region={content.region}
          id={content.id}
        />
      </div>
    </div>
  );
};

export default SimpleCard;
