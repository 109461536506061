import React from "react";
import "./Overview.css";

const Overview = ({ activeWS }) => {
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - Overview</h1>
      </div>
      <div className="main-text">
        <p>
          This portal provides scientifically verified data and management
          scenarios due to climate change in the landscapes primarily related to
          water resources.
        </p>
        <br />
        <p>
          The data and scenarios are generated based on the hydrology/water
          quality model (i.e. physically based semi-distributed model). The data
          and scenarios provided in this portal highlight the potential impacts
          of climate change on hydrology and water quality in the watershed as
          well as various adaptation strategies. This data can be used by
          variety of target audience such as researchers, policymakers, NRCS
          personnel, watershed associations and municipalities.
        </p>
        <br />
        <p>
          Understanding the potential impacts of climate change will help inform
          policy and planning for better climate resilient strategies on water
          resources.
        </p>
        <div className="main-img">
          <img
            src={Object.values(activeWS.img_source)}
            alt="map of watershed"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
