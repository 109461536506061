export const usSediment = [
  {
    name: "Observed TSS(lb/day)",
    data: [
      [6605.0415],
      [2169.3461],
      [1234.5872],
      [1746.059],
      [5423.3652],
      [491.8728],
      [2724.9103],
      [603.8454],
      [3287.0884],
      [353.7313],
      [257.3894],
      [2266.3494],
      [1682.1251],
      [2762.3889],
      [707.1319],
      [2050.2966],
      [210.5633],
      [109.7901],
      [654.0005],
      [1459.4584],
      [2394.2173],
      [2202.4154],
      [497.3623],
      [663.3922],
      [2153.9137],
      [2354.5342],
      [3075.4449],
      [2949.7816],
      [2757.9796],
      [1025.1483],
      [469.9368],
      [570.1368],
      [1556.4617],
      [2198.0061],
      [2136.2768],
      [1642.4419],
      [2268.554],
      [346.456],
      [1803.3792],
      [1097.9008],
      [15604.3004],
      [8289.3712],
      [641.7869],
      [1776.9237],
      [2162.7322],
      [1053.8084],
      [5507.1408],
      [710.4829],
      [1188.2902],
      [846.993],
    ],
    color: "#007FAC",
  },
  {
    name: "Simulated TSS(lb/day)",
    data: [
      [6845.3451],
      [1437.9812],
      [1098.043],
      [1252.2242],
      [5912.9429],
      [641.1888],
      [1721.8082],
      [1509.8091],
      [1282.6164],
      [100.4169],
      [765.9277],
      [3201.1082],
      [1372.5537],
      [1115.8222],
      [9.9848],
      [216.4083],
      [3.6979],
      [776.5952],
      [43.5633],
      [1423.1202],
      [936.2286],
      [1486.3406],
      [368.3849],
      [31.1207],
      [349.0648],
      [855.0776],
      [1241.6989],
      [3473.3433],
      [5780.0412],
      [2595.0511],
      [107.2915],
      [289.0257],
      [1947.1773],
      [532.0957],
      [2194.3318],
      [937.3436],
      [482.8118],
      [2334.0525],
      [494.7902],
      [1699.1321],
      [11486.0702],
      [1273.7014],
      [1573.8142],
      [2966.7886],
      [578.7128],
      [420.421],
      [4987.4194],
      [567.014],
      [681.6543],
      [525.1405],
    ],
    color: "#c03",
  },
];

export const usStreamflow = [
  {
    name: "Observed Streamflow(cfs)",
    data: [
      [19.3532],
      [48.5582],
      [52.3258],
      [27.02],
      [22.6097],
      [43.6133],
      [13.1061],
      [16.4532],
      [26.3896],
      [18.7187],
      [25.2266],
      [47.1548],
      [15.6322],
      [51.3689],
      [24.3161],
      [43.2266],
      [19.5451],
      [13.3613],
      [21.8597],
      [9.0845],
      [7.4167],
      [12.0871],
      [29.01],
      [22.7484],
      [24.4839],
      [25.1893],
      [33.0838],
      [41.9433],
      [13.6684],
      [10.181],
      [14.8116],
      [5.1497],
      [3.7863],
      [43.0838],
      [19.77],
      [21.8645],
      [28.8064],
      [20.7714],
      [10.6145],
      [14.1667],
      [5.9806],
      [20.558],
      [30.1784],
      [8.0016],
      [13.5543],
      [27.8332],
      [35.3333],
      [18.5645],
      [33.5097],
      [15.215],
      [31.3322],
      [72.6466],
      [15.3193],
      [12.8713],
      [6.4058],
      [4.5045],
      [1.9723],
      [7.5555],
      [6.211],
      [16.5642],
      [14.1332],
      [23.3207],
      [23.529],
      [13.2893],
      [18.1874],
      [9.37],
      [6.8061],
      [5.6487],
      [4.8897],
      [4.5416],
      [11.5577],
      [31.5071],
      [21.1877],
      [14.0928],
      [10.5452],
      [23.5333],
      [23.2993],
      [18.7373],
      [6.4813],
      [17.0248],
      [11.8443],
      [20.9093],
      [18.99],
      [78.1258],
      [34.0226],
      [48.0571],
      [79.7096],
      [34.9466],
      [17.3187],
      [13.2287],
      [12.3793],
      [5.9423],
      [6.2657],
      [26.2129],
      [10.2553],
      [12.8645],
      [14.7226],
      [27.5961],
      [34.3419],
      [36.1766],
      [18.2516],
      [8.998],
      [17.9442],
      [169.5067],
      [39.7366],
      [27.1839],
      [32.4266],
      [45.7613],
      [26.6387],
      [18.5345],
      [20.2255],
      [15.3473],
      [8.8806],
      [8.128],
      [2.5474],
      [5.7355],
      [5.1887],
      [17.6745],
      [9.7863],
      [29.3719],
      [17.8029],
      [19.5928],
      [17.9384],
      [12.9317],
      [8.859],
      [61.4873],
      [17.5971],
      [25.111],
      [17.129],
      [7.8048],
      [10.3],
      [28.6397],
      [26.258],
      [55.225],
      [39.0419],
      [35.9133],
      [39.7516],
      [15.0603],
      [11.15],
      [11.8642],
      [9.7793],
      [8.4355],
      [21.8],
      [31.9097],
      [42.2193],
      [21.5786],
      [54.6064],
      [25.97],
      [10.3703],
      [34.5746],
      [30.2693],
      [6.5064],
      [7.3903],
      [12.4245],
      [14.2607],
      [21.7903],
    ],
    color: "#007FAC",
  },
  {
    name: "Simulated Streamflow(cfs)",
    data: [
      [16.047],
      [60.4588],
      [25.3206],
      [22.9828],
      [18.791],
      [35.1805],
      [24.8686],
      [15.5526],
      [13.8822],
      [15.6939],
      [16.7639],
      [39.7997],
      [19.7162],
      [45.8385],
      [14.7262],
      [27.888],
      [16.6014],
      [17.0641],
      [26.6979],
      [8.8958],
      [7.5432],
      [7.522],
      [20.539],
      [9.8387],
      [16.1883],
      [26.8851],
      [19.7762],
      [36.6213],
      [20.027],
      [7.07],
      [10.8204],
      [13.0099],
      [1.7958],
      [25.6138],
      [18.4767],
      [19.0735],
      [25.1123],
      [18.6779],
      [10.9228],
      [15.5314],
      [2.3562],
      [42.0951],
      [44.9556],
      [10.3366],
      [14.8851],
      [17.6997],
      [33.7255],
      [19.5679],
      [36.1269],
      [21.3654],
      [16.5732],
      [60.3175],
      [17.6538],
      [10.0258],
      [0.6819],
      [3.1137],
      [0.3258],
      [7.4726],
      [2.0751],
      [14.8075],
      [11.5868],
      [16.4461],
      [17.7845],
      [14.1753],
      [18.7203],
      [16.9864],
      [5.5197],
      [0.7833],
      [4.6015],
      [1.2102],
      [4.6969],
      [20.592],
      [18.406],
      [12.3884],
      [6.9958],
      [14.8004],
      [16.6827],
      [20.8675],
      [8.5497],
      [30.0281],
      [24.3036],
      [20.2742],
      [18.2471],
      [81.1532],
      [31.0452],
      [50.606],
      [48.2752],
      [31.0557],
      [30.7662],
      [11.7598],
      [12.8369],
      [1.6178],
      [1.7989],
      [17.0393],
      [4.0541],
      [6.0635],
      [12.8157],
      [21.2771],
      [23.1347],
      [23.0888],
      [10.4284],
      [5.6892],
      [15.3654],
      [190.8053],
      [35.4206],
      [28.6861],
      [25.2394],
      [34.4354],
      [24.6143],
      [15.1535],
      [15.1853],
      [16.2271],
      [5.9223],
      [38.1752],
      [15.1959],
      [22.0152],
      [8.9523],
      [54.8084],
      [8.8181],
      [25.6808],
      [19.2783],
      [21.8386],
      [18.4307],
      [17.4737],
      [11.9011],
      [65.2969],
      [37.1511],
      [19.4337],
      [5.6786],
      [2.0249],
      [8.7227],
      [15.6232],
      [19.7833],
      [39.6231],
      [35.4913],
      [23.0676],
      [33.1181],
      [11.0394],
      [1.3946],
      [25.3348],
      [7.0312],
      [1.4832],
      [5.1383],
      [11.8905],
      [38.2458],
      [23.283],
      [43.2605],
      [19.642],
      [10.8593],
      [43.5077],
      [46.792],
      [11.8446],
      [22.9086],
      [13.6173],
      [8.8816],
      [30.1305],
    ],
    color: "#c03",
  },
];

export const usTP = [
  {
    name: "Observed TP(lb/day)",
    data: [
      [11.3979],
      [8.0469],
      [10.8688],
      [3.9683],
      [18.5409],
      [8.9508],
      [8.7964],
      [4.8061],
      [2.7999],
      [2.6896],
      [16.9976],
      [10.7806],
      [18.629],
      [5.71],
      [16.7772],
      [2.4471],
      [1.5653],
      [5.247],
      [10.1853],
      [16.7331],
      [15.8733],
      [4.5415],
      [3.7258],
      [4.5636],
      [18.3865],
      [18.2322],
      [25.6397],
      [23.2146],
      [8.091],
      [5.0706],
      [5.6438],
      [12.875],
      [19.092],
      [22.6635],
      [15.1016],
      [20.8998],
      [4.3651],
      [19.6873],
      [10.1633],
      [264.7308],
      [124.8256],
      [7.165],
      [18.3645],
      [23.6115],
      [8.5319],
      [12.7207],
      [78.8152],
      [7.6941],
      [15.1016],
      [11.0451],
    ],
    color: "#007FAC",
  },
  {
    name: "Simulated TP(lb/day)",
    data: [
      [9.4393],
      [9.3248],
      [11.0385],
      [7.8186],
      [22.9134],
      [22.8966],
      [10.7024],
      [18.6966],
      [3.0516],
      [4.8537],
      [32.0919],
      [10.3866],
      [10.5587],
      [0.5368],
      [4.9426],
      [0.4098],
      [9.3611],
      [1.2012],
      [9.2875],
      [7.6956],
      [22.9992],
      [4.2236],
      [0.8949],
      [6.1377],
      [4.662],
      [7.0382],
      [8.4323],
      [44.1239],
      [23.7743],
      [2.4332],
      [3.4289],
      [28.468],
      [2.1449],
      [15.9394],
      [1.0467],
      [12.6311],
      [22.8356],
      [3.5436],
      [7.9988],
      [150.3992],
      [13.7483],
      [15.9486],
      [42.1712],
      [10.5859],
      [51.4601],
      [6.5514],
      [57.1637],
      [1.5397],
      [1.7153],
      [8.3849],
    ],
    color: "#c03",
  },
];
