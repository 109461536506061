import React from "react";
import { Link } from "react-router-dom";

const Title = ({ text, url, onClick }) => {
  return (
    <div className="card-title">
      <h4>
        <Link
          to={url}
          onClick={(event) => {
            onClick(event.target.firstChild.data);
          }}
        >
          {text}
        </Link>
      </h4>
    </div>
  );
};

export default Title;
