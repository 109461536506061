import React from "react";

const Image = ({ source, size }) => {
  return (
    <img
      className="card-img"
      src={source}
      maxwidth={`${size}px`}
      alt="map of watershed"
    />
  );
};

export default Image;
