import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usRcp45Organic } from "../../../data/upper_salem/detail_tp";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

const options = {
  yAxis: {
    title: { text: "Organic phosphorus (lb/yr)", style: { fontSize: "14px" } },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
  },
  chart: {
    type: "boxplot",
  },
  legend: {
    enabled: false,
  },
  title: { text: "" },
  xAxis: {
    categories: ["Base", "Climate Change"],
    // title: { text: "" },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },

  plotOptions: {
    boxplot: {
      medianColor: "gray",
      colors: ["#E3BA22", "#E6842A"],
    },
    series: {
      colorByPoint: true,
    },
  },
};

const Detail45Organic = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = [usRcp45Organic];
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - RCP 4.5 - Organic P</h1>
      </div>
      <div className="main-text">
        <p>
          The mean annual increase in organic phosphorus is noticed during
          climate change as compared to base scenario. The organic phosphorus in
          the stream consists of sediment phosphorus and phosphorus present in
          the biological materials and waste products. Increased surface runoff
          due to rise in precipitation in the climate change scenario increased
          sediment load in the river and stream which increases organic
          phosphorus load.
        </p>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default Detail45Organic;
