import React from "react";
import SimpleCard from "../components/SimpleCard/SimpleCard";
import "./SelectWatershed.css";

const SelectWatershed = ({ data, onClick }) => {
  return (
    <div className="select-container">
      <h3>Select Watershed</h3>
      {data.map((ws, index) => (
        <SimpleCard key={index} content={ws} onClick={onClick} />
      ))}
    </div>
  );
};

export default SelectWatershed;
