export const usRcp45 = [
  {
    Period: "January",
    Base: 32.82,
    "Climate Change": 33.94,
    "% Change": 3.41,
  },
  {
    Period: "February",
    Base: 33.69,
    "Climate Change": 34.73,
    "% Change": 3.09,
  },
  { Period: "March", Base: 38.28, "Climate Change": 39.42, "% Change": 2.98 },
  { Period: "April", Base: 44.07, "Climate Change": 45.11, "% Change": 2.36 },
  { Period: "May", Base: 49.78, "Climate Change": 50.35, "% Change": 1.15 },
  { Period: "June", Base: 54.7, "Climate Change": 55.4, "% Change": 1.28 },
  { Period: "July", Base: 57.28, "Climate Change": 58.24, "% Change": 1.68 },
  { Period: "August", Base: 56.45, "Climate Change": 57.48, "% Change": 1.82 },
  {
    Period: "September",
    Base: 52.76,
    "Climate Change": 53.91,
    "% Change": 2.18,
  },
  { Period: "October", Base: 46.48, "Climate Change": 47.42, "% Change": 2.02 },
  { Period: "November", Base: 40.63, "Climate Change": 41.7, "% Change": 2.63 },
  { Period: "December", Base: 35.15, "Climate Change": 36, "% Change": 2.42 },
  { Period: "Annual", Base: 45.17, "Climate Change": 46.14, "% Change": 2.15 },
];
