import React from "react";
import "./Footer2.css";
import logo from "../../img/RSUNJ_RED_BLACK.png";

const Footer2 = () => {
  return (
    <footer className="page-footer">
      <div className="page-foot-item">
        <ul>
          <li>
            <a href="http://crssa.rutgers.edu/">
              Center for Remote Sensing and Spatial Analysis
            </a>
          </li>
          <li>
            <a href="https://njwrri.rutgers.edu/">
              New Jersey Water Resources Research Institute
            </a>
          </li>
          <li>
            <a href="https://ora.rutgers.edu/">Office of Research Analytics</a>
          </li>
        </ul>
        <p className="copyright">
          <a href="https://www.rutgers.edu/site-resource/copyright-information">
            Copyright &copy;
            {new Date().getFullYear()}
          </a>{" "}
          Rutgers, The State University of New Jersey
          <br />
          This website was developed by the
          <a href="https://ora.rutgers.edu/"> Office of Research Analytics</a>
        </p>
      </div>
      <div className="page-foot-item">
        <img src={logo} alt="Rutgers Logo" style={{ maxwidth: "464px" }} />
      </div>
    </footer>
  );
};

export default Footer2;
