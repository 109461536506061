import "./App.css";
import React, { useState } from "react";
import Landing from "./pages/Landing";
import SelectWatershed from "./pages/SelectWatershed";
import NotFound from "./components/NotFound";
import Overview from "./pages/Overview";
import { Switch, Route, Redirect } from "react-router-dom";
import NavbarWDropdown from "./components/Navbar/NavbarWDropdown";
import SelectNavbar from "./components/SelectNavbar/NavbarWDropdown";
import { watersheds } from "./data/watersheds";
import { sidebarData } from "./data/sidebarData";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer2 from "./components/Footer2/Footer2";
import Streamflow from "./pages/model_performance/Streamflow";
import Sediment from "./pages/model_performance/Sediment";
import TP from "./pages/model_performance/TP";
import RCP45 from "./pages/uncertainty/RCP45";
import RCP85 from "./pages/uncertainty/RCP85";
import TempRCP45 from "./pages/temperature/RCP45";
import TempRCP85 from "./pages/temperature/RCP85";
import PcpnRCP45 from "./pages/precipitation/RCP45";
import PcpnRCP85 from "./pages/precipitation/RCP85";
import WaterRCP45 from "./pages/water_budget/RCP45";
import WaterRCP85 from "./pages/water_budget/RCP85";
import Detail45Mineral from "./pages/detail_tp/RCP45/Mineral";
import Detail45Organic from "./pages/detail_tp/RCP45/Organic";
import Detail85Mineral from "./pages/detail_tp/RCP85/Mineral";
import Detail85Organic from "./pages/detail_tp/RCP85/Organic";
import Hydrology from "./pages/climate_change_impact/Hydrology";
import WaterQuality from "./pages/climate_change_impact/WaterQuality";
import Composite45 from "./pages/ccv_index/RCP45/Composite";
import Eutrophic45 from "./pages/ccv_index/RCP45/Eutrophic";
import Flooding45 from "./pages/ccv_index/RCP45/Flooding";
import Sediment45 from "./pages/ccv_index/RCP45/Sediment";
import Composite85 from "./pages/ccv_index/RCP85/Composite";
import Eutrophic85 from "./pages/ccv_index/RCP85/Eutrophic";
import Flooding85 from "./pages/ccv_index/RCP85/Flooding";
import Sediment85 from "./pages/ccv_index/RCP85/Sediment";
import Bmp1045Runoff from "./pages/adaptation/Bmp1045Runoff";
import Bmp1045Sediment from "./pages/adaptation/Bmp1045Sediment";
import Bmp1045Streamflow from "./pages/adaptation/Bmp1045Streamflow";
import Bmp1045TP from "./pages/adaptation/Bmp1045TP";
import Bmp1085Runoff from "./pages/adaptation/Bmp1085Runoff";
import Bmp1085Sediment from "./pages/adaptation/Bmp1085Sediment";
import Bmp1085Streamflow from "./pages/adaptation/Bmp1085Streamflow";
import Bmp1085TP from "./pages/adaptation/Bmp1085TP";
import Bmp4645Runoff from "./pages/adaptation/Bmp4645Runoff";
import Bmp4645Sediment from "./pages/adaptation/Bmp4645Sediment";
import Bmp4645Streamflow from "./pages/adaptation/Bmp4645Streamflow";
import Bmp4645TP from "./pages/adaptation/Bmp4645TP";
import Bmp4685Runoff from "./pages/adaptation/Bmp4685Runoff";
import Bmp4685Sediment from "./pages/adaptation/Bmp4685Sediment";
import Bmp4685Streamflow from "./pages/adaptation/Bmp4685Streamflow";
import Bmp4685TP from "./pages/adaptation/Bmp4685TP";

const App = () => {
  const [activeWS, setActiveWS] = useState(null);
  // console.log(activeWS);

  const getActiveWS = (name) => {
    let foundWS = watersheds.filter((d) => d.text === name).shift();

    return setActiveWS(foundWS);
  };

  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/select">
        <SelectContainer onClick={(value) => getActiveWS(value)} />
      </Route>
      <Route>
        <DefaultContainer activeWS={activeWS} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};

const SelectContainer = ({ onClick }) => {
  return (
    <div className="default-container">
      <NavbarWDropdown />
      <Route exact path="/select">
        <SelectWatershed data={watersheds} onClick={onClick} />
      </Route>
    </div>
  );
};

const DefaultContainer = ({ activeWS, data }) => {
  if (activeWS == null) {
    return <Redirect to="/select" />;
  }
  return (
    <div className="default-container">
      <SelectNavbar />
      <div className="default-content">
        <Sidebar data={sidebarData} activeWS={activeWS} />
        <Route exact path="/overview">
          <Overview activeWS={activeWS} data={watersheds} />
        </Route>
        <Route exact path="/model_performance/streamflow">
          <Streamflow activeWS={activeWS} />
        </Route>
        <Route exact path="/model_performance/sediment">
          <Sediment activeWS={activeWS} />
        </Route>
        <Route exact path="/model_performance/tp">
          <TP activeWS={activeWS} />
        </Route>
        <Route exact path="/uncertainty/rcp45">
          <RCP45 activeWS={activeWS} />
        </Route>
        <Route exact path="/uncertainty/rcp85">
          <RCP85 activeWS={activeWS} />
        </Route>
        <Route exact path="/temperature/rcp45">
          <TempRCP45 activeWS={activeWS} />
        </Route>
        <Route exact path="/temperature/rcp85">
          <TempRCP85 activeWS={activeWS} />
        </Route>
        <Route exact path="/precip/rcp45">
          <PcpnRCP45 activeWS={activeWS} />
        </Route>
        <Route exact path="/precip/rcp85">
          <PcpnRCP85 activeWS={activeWS} />
        </Route>
        <Route exact path="/water/rcp45">
          <WaterRCP45 activeWS={activeWS} />
        </Route>
        <Route exact path="/water/rcp85">
          <WaterRCP85 activeWS={activeWS} />
        </Route>
        <Route exact path="/impact/hydrology">
          <Hydrology activeWS={activeWS} />
        </Route>
        <Route exact path="/impact/quality">
          <WaterQuality activeWS={activeWS} />
        </Route>
        <Route exact path="/tp/rcp45/organic">
          <Detail45Organic activeWS={activeWS} />
        </Route>
        <Route exact path="/tp/rcp45/mineral">
          <Detail45Mineral activeWS={activeWS} />
        </Route>
        <Route exact path="/tp/rcp85/organic">
          <Detail85Organic activeWS={activeWS} />
        </Route>
        <Route exact path="/tp/rcp85/mineral">
          <Detail85Mineral activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp45/flooding">
          <Flooding45 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp45/composite">
          <Composite45 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp45/eutroph">
          <Eutrophic45 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp45/sediment">
          <Sediment45 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp85/flooding">
          <Flooding85 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp85/composite">
          <Composite85 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp85/eutroph">
          <Eutrophic85 activeWS={activeWS} />
        </Route>
        <Route exact path="/vulnerable/rcp85/sediment">
          <Sediment85 activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp45/runoff">
          <Bmp1045Runoff activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp45/sediment">
          <Bmp1045Sediment activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp45/streamflow">
          <Bmp1045Streamflow activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp45/tp">
          <Bmp1045TP activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp85/runoff">
          <Bmp1085Runoff activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp85/sediment">
          <Bmp1085Sediment activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp85/streamflow">
          <Bmp1085Streamflow activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp10/rcp85/tp">
          <Bmp1085TP activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp45/runoff">
          <Bmp4645Runoff activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp45/sediment">
          <Bmp4645Sediment activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp45/streamflow">
          <Bmp4645Streamflow activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp45/tp">
          <Bmp4645TP activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp85/runoff">
          <Bmp4685Runoff activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp85/sediment">
          <Bmp4685Sediment activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp85/streamflow">
          <Bmp4685Streamflow activeWS={activeWS} />
        </Route>
        <Route exact path="/adaptation/bmp46/rcp85/tp">
          <Bmp4685TP activeWS={activeWS} />
        </Route>
      </div>
      <Footer2 />
    </div>
  );
};

export default App;
