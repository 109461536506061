import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <h1>Whoops</h1>
      <p>
        Hitting refresh caused us to lose track of which watershed you were
        examining. Click <Link to="/select">here</Link> to choose your watershed
        again.
      </p>
    </div>
  );
};

export default NotFound;
