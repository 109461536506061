import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usQual } from "../../data/upper_salem/climate_change_impact";

const options = {
  chart: {
    type: "column",
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
  },
  title: { text: "" },
  xAxis: {
    categories: ["Sediment", "TP"],
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  yAxis: {
    title: { text: "% Change from Baseline", style: { fontSize: "14px" } },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
};

const WaterQuality = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usQual;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - Water Quality</h1>
      </div>
      <div className="main-text">
        <p>
          Relative to base scenario, the sediment load in the stream increases
          by 8% and 14% under RCP-4.5 and RCP-8.5 scenarios, respectively. It is
          primarily due to increase in surface runoff concomitant with high
          intensity precipitation during winter and early spring when the
          agricultural lands are uncovered. This facilitates more soil erosion
          and export of sediment from landscape into river.
        </p>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default WaterQuality;
