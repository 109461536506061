import React from "react";
import Table from "../../components/Table/Table";
import { usRcp85 } from "../../data/upper_salem/temperature/rcp85";

let data;

const TempRCP85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    data = usRcp85;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Mean Monthly Temperature RCP 8.5</h1>
      </div>
      <div className="main-text">
        <p>
          The average monthly mean temperature between base and climate change
          shows a similar trend. The maximum temperature is observed during July
          while the minimum temperature is noticed during January. A gradual
          increasing trend of temperature is observed between January to July
          while a decreasing trend is found from July to December. According to
          percent change in temperature due to climate change as compared to
          base scenario, maximum increasing temperature is found during winter
          while a minimum increasing temperature is observed in the summer
          months. Overall, roughly 2.5% increase in annual temperature is found
          in the watershed due to climate change.
        </p>
        <br />
        <p>
          <strong>Table: </strong>Monthly ensemble mean temperature (°F) for
          base and climate change as well as % increase in temperature due to
          climate change in the {activeWS.text} Watershed.
        </p>
        <br />
        <Table data={data} />
      </div>
    </div>
  );
};

export default TempRCP85;
