import React from "react";
import map from "../../../img/vul-85-flooding.png";
let floodMap;
const Flooding85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    floodMap = map;
  }
  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Potential to Flooding RCP 8.5</h1>
      </div>
      <div className="main-text">
        <p>
          The vulnerability to flooding in the watershed is categorized into
          three classes: i{")"} high, ii{")"} medium, and iii{")"} low. Out of
          51 subbasins, 15 subbsins are classified as highly vulnerable to
          flooding due to higher increased in surface runoff while 14 and 22
          subbsains are categorized as moderate and low vulnerable to flooding.
          Compared to moderate climate change scenario (RCP-4.5), more subbasins
          are identified as highly vulnerable to flooding due to more intense
          precipitation in this extreme climate change scenario(RCP 8.5)
          compared to moderate scenario.
        </p>
      </div>
      <div className="main-img">
        <img src={floodMap} alt="watershed map" style={{ width: "800px" }} />
      </div>
    </div>
  );
};

export default Flooding85;
