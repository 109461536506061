import React from "react";
import logo from "../../img/logo-usgs.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="landing-footer">
      <div className="foot-1">
        <p className="font-bold">Developed By:</p>
        <ul className="font-light">
          <li>
            <a href="http://crssa.rutgers.edu/">
              Grant F. Walton Center for Remote Sensing and Spatial Analysis
            </a>
          </li>
          <li>
            <a href="https://njwrri.rutgers.edu/">
              New Jersey Water Resources Research Institute
            </a>
          </li>
          <li>
            <a href="https://ora.rutgers.edu/">Office of Research Analytics</a>
          </li>
        </ul>
        <p className="copyright">
          <a href="https://www.rutgers.edu/site-resource/copyright-information">
            Copyright &copy; {new Date().getFullYear()}
          </a>
          Rutgers, The State University of New Jersey
          <br />
          This website was developed by the{" "}
          <a href="https://ora.rutgers.edu/">Office of Research Analytics</a>
        </p>
      </div>

      <div className="foot-2">
        <p className="font-bold">In Partnership With:</p>
        <img src={logo} alt="green USGS logo" style={{ maxWidth: "225" }} />
      </div>
    </footer>
  );
};

export default Footer;
