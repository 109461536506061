import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usBmp10Rcp45Sediment } from "../../data/upper_salem/adaptation";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
let page;
const options = {
  yAxis: {
    title: { text: "Sediment (lb/yr)", style: { fontSize: "14px" } },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
  },
  chart: {
    type: "boxplot",
  },
  legend: {
    enabled: false,
  },
  title: { text: "" },
  xAxis: {
    categories: [
      "Base",
      "Climate Change",
      "Cover Crop",
      "Filter Strip",
      "No Till",
    ],
    // title: { text: "" },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },

  plotOptions: {
    boxplot: {
      medianColor: "gray",
      colors: ["#E3BA22", "#E6842A", "#137B80", "#8E6C8A", "#978F80"],
    },
    series: {
      colorByPoint: true,
    },
  },
};

const Bmp1045Sediment = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = [usBmp10Rcp45Sediment.chart];
    page = usBmp10Rcp45Sediment.page;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Sediment RCP 4.5 BMP 10%</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          The Best Management Practices (BMPs) application area (corn and
          soybean fields) is approximately 10% of total watershed area. The BMPs
          are incorporated in the critical source areas (i.e most pollution
          producing area) in the watershed one-at-a-time to determine their
          effect to reduce the impacts of climate change. The mean sediment load
          in the future climate change scenario is projected to increase by
          nearly {page.increase}% of base scenario. The BMPs are effective in
          reducing sediment load in the watershed. Cover crop and filter strip
          are less effective compared to no till in reducing sediment load. No
          till reduces maximum sediment load by approximately {page.value}% of
          climate change scenario.
        </p>
      </div>
    </div>
  );
};

export default Bmp1045Sediment;
