import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usSediment } from "../../data/upper_salem/model";

const options = {
  chart: {
    type: "spline",
  },
  title: { text: "Sediment Model Performance" },
  xAxis: {
    categories: [
      "2003-May",
      "2003-Aug",
      "2003-Nov",
      "2004-Aug",
      "2004-Nov",
      "2005-Feb",
      "2005-May",
      "2005-Aug",
      "2006-May",
      "2006-Aug",
      "2006-Nov",
      "2007-Mar",
      "2007-May",
      "2007-Jul",
      "2007-Aug",
      "2007-Sep",
      "2007-Oct",
      "2007-Nov",
      "2008-Feb",
      "2008-Apr",
      "2008-May",
      "2008-Jul",
      "2008-Aug",
      "2008-Sep",
      "2008-Nov",
      "2009-Feb",
      "2009-May",
      "2010-Feb",
      "2010-May",
      "2010-Sep",
      "2010-Nov",
      "2011-Mar",
      "2011-May",
      "2011-Nov",
      "2012-Feb",
      "2012-May",
      "2012-Aug",
      "2012-Nov",
      "2013-Feb",
      "2013-Jun",
      "2013-Aug",
      "2013-Dec",
      "2014-Feb",
      "2014-Jun",
      "2014-Aug",
      "2014-Nov",
      "2015-Mar",
      "2015-May",
      "2015-Aug",
      "2015-Nov",
    ],
  },
  yAxis: { title: { text: "Total Suspended Solid (lb/day)" } },
};

const Sediment = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usSediment;
  }
  options.series[1].dashStyle = "dash";

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - Model Performance - Sediment</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          A hydrology/water quality model known as SWAT (Soil and Water
          Assessment Tool) is used to understand the potential impacts of
          climate change on sediment load in the watershed. The SWAT model is
          calibrated and validated using a model evaluation parameter called NSE
          (Nash-Sutcliff efficiency) before using it for different climate
          change scenarios.
        </p>

        <br />

        <p>
          The blue solid line represents the monthly average of sediment load in
          the {activeWS.text} Watershed while the dotted red line depicts the
          model predicted sediment load. From the figure it is evident that both
          observed and model predicted sediment loads are in good agreement with
          each other. In few cases, the model predicted sediment load is lesser
          than the actual sediment load observed in the stream. This may be due
          to the model’s inability to predict few peak flows. The model
          evaluation parameter (i.e. NSE) for sediment load is 0.6 which
          suggests that the model is good for predicting sediment load in the
          watershed.
        </p>
      </div>
    </div>
  );
};

export default Sediment;
