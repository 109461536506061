export const usRcp45Organic = {
  name: "Organic Phosphorus (lb/yr)",
  data: [
    {
      name: "Base",
      low: 1384.06,
      q1: 1421.98,
      median: 1424.18,
      q3: 1567.04,
      high: 1629.43,
      fillColor: "#E3BA22",
      color: "#E3BA22",
    },
    {
      name: "Climate Change",
      low: 1406.11,
      q1: 1513.91,
      median: 1554.48,
      q3: 1602.1,
      high: 1634.28,
      fillColor: "#E6842A",
      color: "#E6842A",
    },
  ],
};

export const usRcp45Mineral = {
  name: "Mineral Phosphorus (lb/yr)",
  data: [
    {
      name: "Base",
      fillColor: "#E3BA22",
      color: "#E3BA22",
      low: 1235.03,
      q1: 1309.99,
      median: 1456.37,
      q3: 1458.14,
      high: 1490.1,
    },
    {
      name: "Climate Change",
      fillColor: "#E6842A",
      color: "#E6842A",
      low: 1250.02,
      q1: 1253.99,
      median: 1295.66,
      q3: 1296.76,
      high: 1303.59,
    },
  ],
};

export const usRcp85Organic = {
  name: "Organic Phosphorus (lb/yr)",
  data: [
    {
      name: "Base",
      fillColor: "#E3BA22",
      color: "#E3BA22",
      low: 1384.06,
      q1: 1421.98,
      median: 1424.18,
      q3: 1567.04,
      high: 1629.43,
    },
    {
      name: "Climate Change",
      fillColor: "#E6842A",
      color: "#E6842A",
      low: 1406.11,
      q1: 1513.91,
      median: 1554.48,
      q3: 1602.1,
      high: 1634.28,
    },
  ],
};

export const usRcp85Mineral = {
  name: "Mineral Phosphorus (lb/yr)",
  data: [
    {
      name: "Base",
      fillColor: "#E3BA22",
      color: "#E3BA22",
      low: 1235.03,
      q1: 1309.99,
      median: 1456.37,
      q3: 1458.14,
      high: 1490.1,
    },
    {
      name: "Climate Change",

      fillColor: "#E6842A",

      color: "#E6842A",
      low: 1250.02,
      q1: 1253.99,
      median: 1295.66,
      q3: 1296.76,
      high: 1303.59,
    },
  ],
};
