import React from "react";
import RutgersHeader from "../components/RutgersHeader/RutgersHeader";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import "./Landing.css";

const Landing = () => {
  return (
    <div className="landing">
      <RutgersHeader />
      <div className="landing-content">
        <header className="landing-title">
          <h1>
            <span className="font-light">NJ </span>
            <span className="font-bold">Watershed Adapt</span>
          </h1>
        </header>

        <Link to="/select" style={{ textDecoration: "none" }} className="start">
          <button className="start">Start Exploring Data</button>
        </Link>
        <div className="landing-subtext">
          For more information regarding NJ Watershed Adapt: Please contact Dr.
          Subhasis Giri at subhasis.giri@rutgers.edu
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
