import React from "react";
import Navbar from "./Navbar";
import NavItem from "./NavItem";
import "./NavbarWDropdown.css";

const NavbarWDropdown = () => {
  return (
    <Navbar>
      <div className="njwa">
        <NavItem icon="NJ Watershed Adapt" url="/" />
      </div>
    </Navbar>
  );
};

export default NavbarWDropdown;
