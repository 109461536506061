import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usBmp10Rcp45Runoff } from "../../data/upper_salem/adaptation";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
let page;
const options = {
  yAxis: {
    title: { text: "Surface Runoff (inch/yr)", style: { fontSize: "14px" } },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
  },
  chart: {
    type: "boxplot",
  },
  legend: {
    enabled: false,
  },
  title: { text: "" },
  xAxis: {
    categories: [
      "Base",
      "Climate Change",
      "Cover Crop",
      "Filter Strip",
      "No Till",
    ],
    // title: { text: "" },
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },

  plotOptions: {
    boxplot: {
      medianColor: "gray",
      colors: ["#E3BA22", "#E6842A", "#137B80", "#8E6C8A", "#978F80"],
    },
    series: {
      colorByPoint: true,
    },
  },
};

const Bmp1045Runoff = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = [usBmp10Rcp45Runoff.chart];
    page = usBmp10Rcp45Runoff.page;
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Surface Runoff RCP 4.5 BMP 10%</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          The Best Management Practices (BMPs) application area (corn and
          soybean fields) is approximately 10% of total watershed area. The BMPs
          are incorporated in the critical source areas (i.e most pollution
          producing area) in the watershed one-at-a-time to determine their
          effect to reduce the impacts of climate change. The mean surface
          runoff in the future climate change scenario is projected to increase
          by nearly {page.increase}% of base scenario. The BMPs (cover crop and
          filter strip) have little to no impact in reducing surface runoff in
          the watershed. However, no till is effective in reducing surface
          runoff by approximately {page.value}% of climate change scenario. In
          no till, soil is minimally disturbed as well as residue is left over
          the entire field. The combination of minimal soil disturbance coupled
          with residue increases the surface roughness which reduces surface
          runoff.
        </p>
      </div>
    </div>
  );
};

export default Bmp1045Runoff;
