import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

const Submenu = ({ item, padding }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubNav = () => setSubnav(!subnav);

  return (
    <>
      {item.path === "#" ? (
        <Link
          className="submenu-link"
          to={item.path}
          exact="true"
          onClick={item.subNav && showSubNav}
          style={{ padding: `${padding}px` }}
        >
          <div className="submenu-label">
            {item.icon}
            <span>{item.title}</span>
          </div>
          <div>
            {/* This is where the dropdown kicks in, if has subnav and if subnav is clicked (state) then show the open icon or ELSE if item has subnav show closed icon ELSE show nothing*/}
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </Link>
      ) : (
        <NavLink
          activeClassName={"current"}
          className="submenu-link"
          to={item.path}
          exact
          onClick={item.subNav && showSubNav}
          style={{ padding: `${padding}px` }}
        >
          <div className="submenu-label">
            {item.icon}
            <span>{item.title}</span>
          </div>
          <div>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </NavLink>
      )}

      {/* map through to add submenu items if subnav is true, map through the subNav of the item */}
      {subnav &&
        item.subNav.map((sub, index) => {
          return <Submenu item={sub} key={index} padding={padding + 10} />;
        })}
    </>
  );
};

export default Submenu;
