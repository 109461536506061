import React from "react";

const Description = ({ wma, region, id }) => {
  return (
    <ul className="card-desc">
      <li>WMA: {wma}</li>
      <li>Watershed Region: {region}</li>
      <li>Watershed ID: {id}</li>
    </ul>
  );
};

export default Description;
