import React from "react";
import Navbar from "./Navbar";
import NavItem from "./NavItem";
import "./NavbarWDropdown.css";

const SelectNavbar = () => {
  return (
    <Navbar>
      <div className="njwa">
        <NavItem icon="NJ Watershed Adapt" url="/" />
      </div>

      <NavItem icon="Select Watershed" url="/select" />
    </Navbar>
  );
};

export default SelectNavbar;
