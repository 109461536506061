import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  usRcp85Base,
  usRcp85Change,
} from "../../data/upper_salem/water_budget";

const options1 = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
  },
  title: { text: "RCP 8.5 Base" },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      depth: 35,
      dataLabels: {
        enabled: true,
        format: "{point.name}",
      },
    },
  },
};
const options2 = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
  },
  title: { text: "RCP 8.5 Climate Change" },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      depth: 35,
      dataLabels: {
        enabled: true,
        format: "{point.name}",
      },
    },
  },
};

const WaterRCP85 = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options1.series = [usRcp85Base];
    options2.series = [usRcp85Change];
  }

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} Water Budget RCP 8.5</h1>
      </div>
      <div className="main-text">
        <p>
          All components of water budget are expressed in inch (in). A change in
          different components of water budget is observed under RCP 8.5 as
          compared to base scenario due to increase in precipitation and
          temperature in the former. All components of water budget depict an
          increasing trend in the climate change as compared to base scenario.
          The gain in each component of water budget under climate change is in
          the decreasing order of groundwater, evapotranspiration (ET), surface
          runoff, and lateral flow. However, ET represents maximum percentage of
          water in the water budget followed by groundwater, surface runoff, and
          lateral flow.
        </p>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options1} />
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options2} />
      </div>
    </div>
  );
};

export default WaterRCP85;
