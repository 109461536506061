export const usRcp85 = [
  { Period: "January", Base: 32.87, "Climate Change": 34.01, "% Change": 3.47 },
  {
    Period: "February",
    Base: 34.21,
    "Climate Change": 35.26,
    "% Change": 3.07,
  },
  { Period: "March", Base: 38.52, "Climate Change": 39.41, "% Change": 2.31 },
  { Period: "April", Base: 44.11, "Climate Change": 45.15, "% Change": 2.36 },
  { Period: "May", Base: 49.89, "Climate Change": 50.5, "% Change": 1.22 },
  { Period: "June", Base: 54.72, "Climate Change": 55.63, "% Change": 1.66 },
  { Period: "July", Base: 57.29, "Climate Change": 58.41, "% Change": 1.95 },
  { Period: "August", Base: 56.44, "Climate Change": 57.89, "% Change": 2.57 },
  {
    Period: "September",
    Base: 52.59,
    "Climate Change": 54.22,
    "% Change": 3.1,
  },
  { Period: "October", Base: 46.5, "Climate Change": 47.59, "% Change": 2.34 },
  {
    Period: "November",
    Base: 40.56,
    "Climate Change": 41.82,
    "% Change": 3.11,
  },
  { Period: "December", Base: 35.2, "Climate Change": 36.41, "% Change": 3.44 },
  { Period: "Annual", Base: 45.24, "Climate Change": 46.36, "% Change": 2.48 },
];
