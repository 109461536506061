import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { usStreamflow } from "../../data/upper_salem/model";

const options = {
  chart: {
    type: "spline",
  },

  title: { text: "Streamflow Model Performance" },
  xAxis: {
    categories: [
      "2003-Jan",
      "2003-Feb",
      "2003-Mar",
      "2003-Apr",
      "2003-May",
      "2003-Jun",
      "2003-Jul",
      "2003-Aug",
      "2003-Sep",
      "2003-Oct",
      "2003-Nov",
      "2003-Dec",
      "2004-Jan",
      "2004-Feb",
      "2004-Mar",
      "2004-Apr",
      "2004-May",
      "2004-Jun",
      "2004-Jul",
      "2004-Aug",
      "2004-Sep",
      "2004-Oct",
      "2004-Nov",
      "2004-Dec",
      "2005-Jan",
      "2005-Feb",
      "2005-Mar",
      "2005-Apr",
      "2005-May",
      "2005-Jun",
      "2005-Jul",
      "2005-Aug",
      "2005-Sep",
      "2005-Oct",
      "2005-Nov",
      "2005-Dec",
      "2006-Jan",
      "2006-Feb",
      "2006-Mar",
      "2006-Apr",
      "2006-May",
      "2006-Jun",
      "2006-Jul",
      "2006-Aug",
      "2006-Sep",
      "2006-Oct",
      "2006-Nov",
      "2006-Dec",
      "2007-Jan",
      "2007-Feb",
      "2007-Mar",
      "2007-Apr",
      "2007-May",
      "2007-Jun",
      "2007-Jul",
      "2007-Aug",
      "2007-Sep",
      "2007-Oct",
      "2007-Nov",
      "2007-Dec",
      "2008-Jan",
      "2008-Feb",
      "2008-Mar",
      "2008-Apr",
      "2008-May",
      "2008-Jun",
      "2008-Jul",
      "2008-Aug",
      "2008-Sep",
      "2008-Oct",
      "2008-Nov",
      "2008-Dec",
      "2009-Jan",
      "2009-Feb",
      "2009-Mar",
      "2009-Apr",
      "2009-May",
      "2009-Jun",
      "2009-Jul",
      "2009-Aug",
      "2009-Sep",
      "2009-Oct",
      "2009-Nov",
      "2009-Dec",
      "2010-Jan",
      "2010-Feb",
      "2010-Mar",
      "2010-Apr",
      "2010-May",
      "2010-Jun",
      "2010-Jul",
      "2010-Aug",
      "2010-Sep",
      "2010-Oct",
      "2010-Nov",
      "2010-Dec",
      "2011-Jan",
      "2011-Feb",
      "2011-Mar",
      "2011-Apr",
      "2011-May",
      "2011-Jun",
      "2011-Jul",
      "2011-Aug",
      "2011-Sep",
      "2011-Oct",
      "2011-Nov",
      "2011-Dec",
      "2012-Jan",
      "2012-Feb",
      "2012-Mar",
      "2012-Apr",
      "2012-May",
      "2012-Jun",
      "2012-Jul",
      "2012-Aug",
      "2012-Sep",
      "2012-Oct",
      "2012-Nov",
      "2012-Dec",
      "2013-Jan",
      "2013-Feb",
      "2013-Mar",
      "2013-Apr",
      "2013-May",
      "2013-Jun",
      "2013-Jul",
      "2013-Aug",
      "2013-Sep",
      "2013-Oct",
      "2013-Nov",
      "2013-Dec",
      "2014-Jan",
      "2014-Feb",
      "2014-Mar",
      "2014-Apr",
      "2014-May",
      "2014-Jun",
      "2014-Jul",
      "2014-Aug",
      "2014-Sep",
      "2014-Oct",
      "2014-Nov",
      "2014-Dec",
      "2015-Jan",
      "2015-Feb",
      "2015-Mar",
      "2015-Apr",
      "2015-May",
      "2015-Jun",
      "2015-Jul",
      "2015-Aug",
      "2015-Sep",
      "2015-Oct",
      "2015-Nov",
      "2015-Dec",
    ],
  },
  yAxis: { title: { text: "Streamflow (cubic feet/sec)" } },
  // xAxis: { type: "category" }, // this works for dates but is too crowded
};

const Streamflow = ({ activeWS }) => {
  if (activeWS.data === "upper_salem") {
    options.series = usStreamflow;
  }
  options.series[1].dashStyle = "dash";

  return (
    <div className="main-content">
      <div className="main-title">
        <h1>{activeWS.text} - Model Performance - Streamflow</h1>
      </div>
      <div className="main-img">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="main-text">
        <p>
          A hydrology/water quality model known as SWAT (Soil and Water
          Assessment Tool) is used to understand the potential impacts of
          climate change on streamflow. The SWAT model is calibrated and
          validated using a model evaluation parameter called NSE (Nash-Sutcliff
          efficiency) before using it for different climate change scenarios.
        </p>
        <br />
        <p>
          The blue color solid line is the observed monthly average streamflow
          from the {activeWS.text} Watershed whilst the dotted red line is the
          model predicted streamflow. From the figure it is clear that both
          solid and dotted lines are matching quite well. Additionally, the
          model evaluation parameter (i.e. NSE) is 0.76 which is higher than 0.5
          (recommended). This suggests that the hydrologic model is very good
          for predicting streamflow in this watershed. However, few peaks of
          streamflow are not matched well by model which may be due to
          measurement error with precipitation and streamflow in the field
          combined with inability of the model to capture the sudden increase
          and decrease of streamflow during storm event.
        </p>
      </div>
    </div>
  );
};

export default Streamflow;
